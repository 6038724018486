<script lang="ts">
    import { _ } from 'svelte-i18n';

    export let username = '';
    export let workspaceName = '';
    export let hasAiPlugin = true;
    export let subscriptionStatus: MtmLauncherDto['subscriptionStatus'] = 'EXPIRED';
</script>

<div class="inline-flex w-96 items-center justify-start gap-2 rounded-lg border border-gray-200 bg-white p-4 text-left shadow-lg hover:bg-gray-100">
    <div class="flex shrink grow basis-0 items-center justify-start gap-4 self-stretch">
        <div class="flex h-10 w-10 items-center justify-center gap-2 rounded-lg bg-gray-500">
            <div class="relative h-6 w-6 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                        d="M19 21V5C19 4.46957 18.7893 3.96086 18.4142 3.58579C18.0391 3.21071 17.5304 3 17 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V21M19 21H5M19 21H21M19 21H14M5 21H3M5 21H10M14 21V16C14 15.7348 13.8946 15.4804 13.7071 15.2929C13.5196 15.1054 13.2652 15 13 15H11C10.7348 15 10.4804 15.1054 10.2929 15.2929C10.1054 15.4804 10 15.7348 10 16V21M14 21H10M9 7H10M9 11H10M14 7H15M14 11H15"
                        stroke="white"
                        stroke-width="1.4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </div>
        <div class="flex shrink grow basis-0 items-center justify-start gap-2 self-stretch">
            <div class="inline-flex shrink grow basis-0 flex-col items-start justify-center">
                <div class="self-stretch text-sm font-medium leading-tight text-gray-900">{workspaceName}</div>
                <div class="self-stretch text-xs font-normal leading-none text-gray-500">{$_('routes.launcherWorkspaceCard.gotoWorkspaceDesc', { values: { username } })}</div>
            </div>
            {#if hasAiPlugin}
                <div class="flex items-center justify-center gap-0.5 rounded-full bg-violet-100 px-2 py-0.5">
                    <div class="relative h-4 w-4 rounded">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                            <path
                                d="M14.412 9.23375H12.6585C12.607 9.23375 12.578 9.27345 12.5899 9.32363C12.6889 9.74879 12.5437 10.2214 12.1542 10.5145C11.7515 10.8169 11.1784 10.8169 10.7756 10.5145C10.3861 10.2214 10.2409 9.74879 10.3399 9.32363C10.3518 9.27345 10.3227 9.23375 10.2712 9.23375H8.70532V7.58856C8.70532 7.53575 8.74618 7.50808 8.79636 7.51996C9.17663 7.61503 9.59526 7.51339 9.89367 7.2163C10.3439 6.76605 10.3439 6.03322 9.89367 5.58297C9.59526 5.28457 9.17663 5.1828 8.79636 5.27918C8.74618 5.29239 8.70532 5.2634 8.70532 5.21059V3.5271H14.1268C14.2839 3.5271 14.412 3.6552 14.412 3.81232V9.23375Z"
                                fill="#2D2D41"
                            />
                            <path
                                d="M7.4127 11.39C7.10242 11.794 7.10242 12.3776 7.4127 12.7816C7.71243 13.1725 8.18769 13.3151 8.61549 13.2082C8.66566 13.195 8.70665 13.224 8.70665 13.2768V14.9405H3.28509C3.12797 14.9405 3 14.8124 3 14.6553V9.23376H4.75337C4.80487 9.23376 4.83398 9.19419 4.8221 9.14402C4.72175 8.71886 4.867 8.24741 5.25651 7.95297C5.65923 7.64796 6.2362 7.64796 6.63891 7.95297C7.02843 8.24873 7.17238 8.71886 7.07204 9.14402C7.06015 9.19419 7.08914 9.23376 7.14063 9.23376H8.70536V10.8975C8.70536 10.9503 8.66437 10.978 8.6142 10.9661C8.1864 10.8578 7.71101 11.0004 7.41129 11.3912L7.4127 11.39Z"
                                fill="#2D2D41"
                            />
                            <path
                                d="M9.89493 7.21622C9.59652 7.51331 9.17789 7.61495 8.79762 7.51989C8.74744 7.50668 8.70658 7.53567 8.70658 7.58848V9.23367H7.14185C7.09036 9.23367 7.06137 9.1941 7.07326 9.14393C7.17361 8.71877 7.02965 8.24732 6.64014 7.95288C6.23742 7.64787 5.66045 7.64787 5.25773 7.95288C4.86822 8.24864 4.72429 8.71877 4.82332 9.14393C4.8352 9.1941 4.80622 9.23367 4.75472 9.23367H3.00122V3.81224C3.00122 3.6538 3.12932 3.52702 3.28644 3.52702H4.76001C4.81283 3.52702 4.84049 3.48603 4.82861 3.43586C4.73222 3.05559 4.83386 2.63576 5.13227 2.33868C5.58252 1.88711 6.31535 1.88711 6.7656 2.33868C7.064 2.63576 7.16568 3.05559 7.06797 3.43586C7.05477 3.48735 7.08378 3.52702 7.13528 3.52702H8.70658V5.21051C8.70658 5.26332 8.74744 5.29099 8.79762 5.27911C9.17789 5.18272 9.59652 5.28449 9.89493 5.5829C10.3452 6.03315 10.3452 6.76597 9.89493 7.21622Z"
                                fill="#05D296"
                            />
                            <path
                                d="M15.7059 12.7816C15.4061 13.1724 14.9308 13.3151 14.5029 13.2081C14.4528 13.1949 14.4119 13.2239 14.4119 13.2767V14.6553C14.4119 14.8124 14.2851 14.9405 14.1267 14.9405H8.70526V13.2767C8.70526 13.2239 8.66427 13.1963 8.6141 13.2081C8.18629 13.3151 7.71091 13.1724 7.41118 12.7816C7.10089 12.3776 7.10089 11.7953 7.41118 11.3899C7.71091 10.9991 8.18629 10.8565 8.6141 10.9648C8.66427 10.978 8.70526 10.9489 8.70526 10.8961V9.23242H10.2712C10.3226 9.23242 10.3518 9.27199 10.3399 9.32217C10.2408 9.74733 10.3847 10.2214 10.7756 10.5132C11.1783 10.8156 11.7527 10.8156 12.1541 10.5132C12.5436 10.2201 12.6888 9.74733 12.5898 9.32217C12.5779 9.27199 12.6069 9.23242 12.6584 9.23242H14.4119V10.8961C14.4119 10.9489 14.4528 10.9767 14.5029 10.9648C14.9308 10.8565 15.4061 10.9991 15.7059 11.3899C16.0162 11.7939 16.0162 12.3762 15.7059 12.7816Z"
                                fill="#05D296"
                            />
                        </svg>
                    </div>
                    <div class="text-center text-xs font-semibold leading-none text-gray-900">AI Plugin</div>
                </div>
            {/if}
        </div>
    </div>
    <div class="relative flex h-6 items-center rounded">
        {#if subscriptionStatus === 'ACTIVE'}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M14 5L21 12M21 12L14 19M21 12H3" stroke="#4B5563" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        {:else}
            <div class="text-xs text-gray-500">{$_('apps.contract.routes.launcherWorkspaceCard.expiredMessage')}</div>
        {/if}
    </div>
</div>
